import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";

import { LoginHeader } from "../../navigation/login-header";

import {
  createLicense,
  getLicenses,
  getOrders,
  getUser,
  saveUserInfo,
  editUserRole,
} from "../../../../services/user.service";

import "../../content-management/content-management-page.scss";

import { EditValue } from "../../values/ValuesLookUp";
import { ArrowIcon } from "../../../../Icons";
import { ISessionUserDto } from "../../../../dtos/session-user.dto";
import { IOrderDto } from "../../../../dtos/order.dto";
import { ILicenseDto, LicenseStatus } from "../../../../dtos/license.dto";
import { MessageBox, Modal } from "../../../helpers/message-box/message-box";
import {
  IError,
  serverResponse,
  statusToVariant,
} from "../../../../dtos/error.dto";
import { useErrorHandler } from "../../../../hooks/error-hook";
import SessionService from "../../../../services/session.service";
import API from "../../../../API";
import { PDFExport } from "../PDFExport";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import { PDFExport } from "../PDFExport";

export const SingleUserPage: React.FC<{}> = () => {
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState<ISessionUserDto>({
    email: "placeholder@email.nyu.edu",
    firstName: "Place",
    fullName: "Place Holder",
    id: "1",
    lastName: "Holder",
    roles: [{ id: "1", userId: "1", type: "Owner" }],
    stripeCustomerId: "22",
    orderCount: 0,
  });
  const [editUser, setEditUser] = useState<boolean>(false);
  const { httpError, updateHttpError } = useErrorHandler();

  const { id } = useParams<{ id: string }>();

  const fetchAPI = async () => {
    try {
      const result = await getUser(id);
      setCurrentUser(result);
    } catch (error) {
      updateHttpError({
        message: (error as IError).messages[0],
        statusCode: (error as IError).statusCode,
      });
    }
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [editUser]);

  const handleEditUser = () => {
    setEditUser(!editUser);
  };

  return (
    <div>
      <div className="single-user-outer">
        <LoginHeader name={currentUser.fullName} />
        <span className="page-title">
          You are currently editing the profile for{" "}
          <span>{currentUser.fullName}</span>
        </span>
        {httpError.statusCode > 399 && (
          <MessageBox
            message={httpError.message}
            variant={statusToVariant(httpError.statusCode)}
          />
        )}
        <div className="single-user-upper-wrapper">
          <BasicInfo
            first_name={currentUser.firstName}
            last_name={currentUser.lastName}
            email={currentUser.email}
            stripeCustomerId={currentUser.stripeCustomerId}
            id={Number(currentUser.id)}
            handleEditUser={handleEditUser}
          />
          {/* <BillingInfo /> */}
          <div id="single-user-group-functionalities">
            <ChangeUserPassword
              name={currentUser.firstName}
              id={Number(currentUser.id)}
            />
            <ChangeUserRole
              name={currentUser.firstName}
              id={Number(currentUser.id)}
              role={currentUser.roles[0]?.type}
              handleEditUser={handleEditUser}
            />
            <div>
              <a
                target="_blank"
                href={`${process.env.REACT_APP_API_BASE_URL}/users/${
                  currentUser.id
                }/exportcsv?token=${SessionService.getInstance().token}`}
              >
                <span>Export User Tests</span>
              </a>
            </div>
          </div>
        </div>
        <div>
          <LicensesTable
            userId={id}
            userName={`${currentUser.firstName} ${currentUser.lastName}`}
            name={"licenses"}
            handleEditUser={handleEditUser}
          />
        </div>
        <div>
          <OrdersTable userId={id} name={"orders"} editUser={editUser} />
        </div>
      </div>
    </div>
  );
};

interface BasicInfoProps {
  first_name: string;
  last_name: string;
  email: string;
  stripeCustomerId: string;
  id: number;
  handleEditUser(): void;
}

export interface IBasicInfo {
  first_name: string;
  last_name: string;
  email: string;
}

const BasicInfo: React.FC<BasicInfoProps> = ({
  first_name,
  last_name,
  email,
  stripeCustomerId,
  id,
  handleEditUser,
}) => {
  const labels = ["First Name", "Last Name", "Email"];
  const info = [first_name, last_name, email];

  const [editToggle, setEditToggle] = useState(false);
  const [basicInfo, setBasicInfo] = useState<IBasicInfo>({
    first_name: first_name,
    last_name: last_name,
    email: email,
  });
  const [response, setResponse] = useState<serverResponse>({
    message: "",
    statusCode: 0,
  });

  const handleEditToggle = (e: any) => {
    setEditToggle(!editToggle);
  };

  useEffect(() => {
    setBasicInfo({ first_name, last_name, email });
  }, [first_name, last_name, email]);

  const pickInfoValue = (index: number): string => {
    let returnValue = "";
    if (index === 0) {
      returnValue = basicInfo.first_name;
    } else if (index === 1) {
      returnValue = basicInfo.last_name;
    } else if (index === 2) {
      returnValue = basicInfo.email;
    }
    return returnValue;
  };
  const handleChange = (e: any) => {
    let newState = { ...basicInfo };
    if (e.target.name === labels[0]) {
      newState.first_name = e.target.value;
    } else if (e.target.name === labels[1]) {
      newState.last_name = e.target.value;
    } else if (e.target.name === labels[2]) {
      newState.email = e.target.value;
    }
    setBasicInfo(newState);
  };
  const Save = async () => {
    const result = await saveUserInfo(id, basicInfo);
    if (result.statusCode > 400) {
      setResponse(result);
      setBasicInfo({
        first_name: first_name,
        last_name: last_name,
        email: email,
      });
      setEditToggle(false);
      setTimeout(() => {
        setResponse({
          message: "",
          statusCode: 0,
        });
      }, 3000);
    } else {
      setResponse(result);
      handleEditUser();
      setEditToggle(false);
      setTimeout(() => {
        setResponse({
          message: "",
          statusCode: 0,
        });
      }, 3000);
    }
  };

  return (
    <div className="info-wrapper basic">
      <form id="user-basic-info">
        <TableHeader
          title="Basic Info"
          handleClick={handleEditToggle}
          editToggle={editToggle}
          Save={Save}
        />

        {Object.keys(basicInfo).map((value, index) => {
          return (
            <FormGroup key={"basic-" + index}>
              <FormLabel className="info-label">{labels[index]}</FormLabel>
              <FormControl
                className={editToggle ? "" : "read-only"}
                type="text"
                name={labels[index]}
                placeholder={info[index]}
                disabled={editToggle ? false : true}
                value={pickInfoValue(index)}
                onChange={handleChange}
              ></FormControl>
            </FormGroup>
          );
        })}
        <a
          target="_blank"
          href={
            process.env.REACT_APP_STRIPE_CUSTOMER_BASE_URL
              ? `${process.env.REACT_APP_STRIPE_CUSTOMER_BASE_URL}/${stripeCustomerId}`
              : `https://dashboard.stripe.com/test/customers/${stripeCustomerId}`
          }
        >
          <FormGroup>
            <FormLabel className="info-label">{"Stripe Customer Id"}</FormLabel>
            <FormControl
              className={"read-only"}
              type="text"
              name={"Stripe Customer Id"}
              placeholder={stripeCustomerId}
              disabled={true}
            ></FormControl>
          </FormGroup>
        </a>
      </form>
      {response.message !== "" && (
        <MessageBox
          message={response.message}
          variant={statusToVariant(response.statusCode)}
        />
      )}
    </div>
  );
};

interface HeaderProps {
  title: string;
  editToggle: boolean;
  handleClick(e: any): any;
  Save(): void;
}

const TableHeader: React.FC<HeaderProps> = ({
  title,
  handleClick,
  editToggle,
  Save,
}) => {
  return (
    <React.Fragment>
      <div className="header">
        <span>{title}</span>
        {editToggle ? (
          <div>
            <span id="cancel" onClick={handleClick}>
              Cancel
            </span>
            <Button
              onClick={() => {
                Save();
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <span className="edit" onClick={handleClick}>
            Edit
          </span>
        )}
      </div>

      <hr />
    </React.Fragment>
  );
};

interface LicensesTableProps {
  userId: string;
  userName: string;
  name?: string;
  handleEditUser(): void;
}

// export const getNarrativeAndUser = async (licenseCode: string) => {
//   const result = await API.get(
//     `narrative/public-narrative/${licenseCode}`
//   );
//   return result.data;
// };

export const getNarrativeData = async (licenseCode: string) => {
  const result = await API.get(`narrative/get-values/${licenseCode}`);
  return result.data;
};

const LicensesTable: React.FC<LicensesTableProps> = (props) => {
  const [licenses, setLicenses] = useState<Array<ILicenseDto>>([]);
  const [added, setAdded] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [response, setResponse] = useState<serverResponse>({
    message: "",
    statusCode: 0,
  });

  const addLicense = async () => {
    let response: serverResponse = { message: "", statusCode: 0 };
    setOpenModal(false);
    try {
      const newOrder = await createLicense(props.userId);
      response.message = "New License created.";
      response.statusCode = 201;
    } catch (error) {
      response.message = (error as IError).messages[0];
      response.statusCode = (error as IError).statusCode;
    }
    setResponse(response);
    setAdded(!added);

    setTimeout(() => {
      setResponse({ message: "", statusCode: 0 });
    }, 3000);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const fetchOrders = async () => {
    const data = await getLicenses(props.userId);
    setLicenses(data.result); //Should we be fetching licenses instead of orders?
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    fetchOrders();
    props.handleEditUser();
  }, [added]);

  const createLink = (license: ILicenseDto) => {
    // if (license.status === "completed") {
    return `${process.env.REACT_APP_EXPERIENCE_APP_URL}/digital-fingerprint/${license.code}`;
    // } else {
    //   return "";
    // }
  };

  return (
    <React.Fragment>
      {openModal && (
        <Modal
          message={`Are you sure you want to add a license for ${props.userName}? `}
          onClose={handleCancel}
          onConfirm={addLicense}
        />
      )}
      <table id={props.name ? props.name : ""}>
        <thead>
          <tr>
            <td id="name">Licenses</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {licenses.map((value, index) => {
            return (
              <LicensesTableRow
                key={`license` + index}
                code={value.code}
                status={value.status}
                link={createLink(value)}
                userName={props.userName}
              />
            );
          })}
        </tbody>
      </table>
      <div className="add-license-wrapper">
        <span
          id="add-license"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          + Create New License
        </span>
        {response.message !== "" && (
          <MessageBox
            message={response.message}
            variant={statusToVariant(response.statusCode)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

interface OrdersTableProps {
  userId: string;
  name?: string;
  editUser: boolean;
}

const OrdersTable: React.FC<OrdersTableProps> = ({
  userId,
  name,
  editUser,
}) => {
  const [orders, setOrders] = useState<Array<IOrderDto>>([]);

  const fetchOrders = async () => {
    const data = await getOrders(userId);
    setOrders(data.result); //Should we be fetching licenses instead of orders?
  };

  useEffect(() => {
    fetchOrders();
  }, [editUser]);

  return (
    <div>
      <table id={name ? name : ""}>
        <thead>
          <tr>
            <td id="name">Orders</td>
            <td id="keys">
              Keys <span>UNUSED/TOTAL</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            return <OrdersTableRow key={`order-${order.id}`} order={order} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

interface OrdersTableRowProps {
  order: IOrderDto;
}

const OrdersTableRow: React.FC<OrdersTableRowProps> = ({ order }) => {
  const calculateKeysUsed = (licenses: Array<ILicenseDto>) => {
    let count: number = 0;
    licenses.map((license) => {
      if (license.status !== "active") {
        count++;
      }
    });
    return count;
  };

  const unUsedKeys: number = calculateKeysUsed(order.licenses);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <>
      <tr>
        <td>{order.id}</td>
        <td>{`${unUsedKeys}/${order.licenses.length}`}</td>
        <td>
          {order.txn !== "automatic" && (
            <a
              target="_blank"
              href={`${process.env.REACT_APP_STRIPE_PAYMENT_BASE_URL}/${order.txn}`}
            >
              <span id="order">Order Details</span>
              <ArrowIcon direction="right" color="#82AAF9" />
            </a>
          )}
        </td>
        <td>
          <button
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            details
          </button>{" "}
          <a
            style={{ display: "inline" }}
            target="_blank"
            href={`${process.env.REACT_APP_API_BASE_URL}/orders/${
              order.id
            }/exportcsv?token=${SessionService.getInstance().token}`}
          >
            export csv
          </a>
        </td>
      </tr>
      {showDetails && (
        <tr>
          <td colSpan={5}>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "left" }}>Code: {order.code}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    Date: {new Date(order.timestamp).toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    Total: {(order.total / 100).toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    License Count: {order.licenseCount.toLocaleString()}
                  </td>
                </tr>
                {order.licenses
                  .filter((license) =>
                    [LicenseStatus.USED, LicenseStatus.COMPLETED].includes(
                      license.status
                    )
                  )
                  .map((license, idx) => {
                    return (
                      <tr key={`license-${idx}`}>
                        <td style={{ textAlign: "left" }}>
                          <pre style={{ display: "inline" }}>
                            [{license.code}] {license.status.toUpperCase()}
                          </pre>{" "}
                          by{" "}
                          <Link
                            style={{ display: "inline" }}
                            target="_blank"
                            to={"/user-management/" + license.user?.id}
                          >
                            <span>
                              {license.user?.firstName} {license.user?.lastName}
                            </span>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

interface LicensesTableRowProps {
  code: string;
  status: string;
  link: string;
  userName: string;
}

const LicensesTableRow: React.FC<LicensesTableRowProps> = (props) => {
  const loadValues = async () => {
    const narrativeData = await getNarrativeData(props.code);

    setNarrativeData(narrativeData);
  };

  const [narrativeData, setNarrativeData] = useState({} as any);

  useEffect(() => {
    loadValues();
  }, []);

  return (
    <React.Fragment>
      <tr>
        <td>{props.code}</td>

        <td>{props.status}</td>
        <td>
          {props.link !== "" &&
            (narrativeData?.valuesPhilosophy ? (
              <a target="_blank" href={props.link}>
                <span id="order">View Fingerprint</span>
                <ArrowIcon direction="right" color="#82AAF9" />
              </a>
            ) : (
              "No Lapin 2.0 data found for this record"
            ))}
        </td>

        {/* Legacy */}
        {/* {narrativeData?.narrative && narrativeData?.rankedValues && (
          <td style={{ width: 250, textAlign: "left" }}>
            <PDFDownloadLink
              document={
                <PDFExportOld
                  narrative={narrativeData?.narrative}
                  values={narrativeData?.rankedValues?.split(",")}
                  name={`${narrativeData?.license?.user?.firstName} ${narrativeData?.license?.user?.lastName}`}
                  checkLoading={false}
                />
              }
              fileName="Your_Fingerprint.pdf"
              className="btn-white no-wrap"
              style={{ textDecoration: "none" }}
            >
              {({ blob, url, error }) => (
                <a target="_blank">
                  <span id="pdf">Download PDF</span>
                  <ArrowIcon direction="right" color="#82AAF9" />
                </a>
              )}
            </PDFDownloadLink>
          </td>
        )} */}

        {narrativeData?.valuesPhilosophy ? (
          <td style={{ width: 250, textAlign: "left" }}>
            <PDFDownloadLink
              document={
                <PDFExport
                  userName={props.userName}
                  valueDrivers={narrativeData?.rankedValues?.split(",")}
                  valuePhilosophy={narrativeData?.valuesPhilosophy}
                  archetype={narrativeData?.archetype}
                  archetypeExplanation={narrativeData?.archetypeExplanation}
                  triggers={narrativeData?.triggers?.split(",")}
                  triggersExplanation={narrativeData?.triggersExplanation
                    ?.split(";;")
                    ?.reduce((acc: any, curr: any) => {
                      const explanation = curr?.split(":");

                      return {
                        ...acc,
                        [explanation[0]]: explanation[1],
                      };
                    }, {})}
                />
              }
              fileName="Your_Fingerprint.pdf"
              className="btn-white no-wrap"
              style={{ textDecoration: "none" }}
            >
              {({ blob, url, error }) => (
                <a target="_blank">
                  <span id="pdf">Download PDF</span>
                  <ArrowIcon direction="right" color="#82AAF9" />
                </a>
              )}
            </PDFDownloadLink>
          </td>
        ) : (
          ""
        )}

        {false && (
          <td style={{ width: 250, textAlign: "left" }}>
            No narrative data found for this record. This may happen if users
            don't finish the questenary.
          </td>
        )}
      </tr>
    </React.Fragment>
  );
};

interface ChangeUserPasswordProps {
  name: string;
  id: number;
}

const ChangeUserPassword: React.FC<ChangeUserPasswordProps> = (props) => {
  const handleAdded = () => {};

  const handleChangePassword = (response: serverResponse) => {
    setResponse(response);

    setTimeout(() => {
      setResponse({ message: "", statusCode: 0 });
    }, 3000);
  };
  const [response, setResponse] = useState<serverResponse>({
    message: "",
    statusCode: 0,
  });

  return (
    <React.Fragment>
      <div className="change-password-wrapper">
        <div className="add-license-wrapper">
          {response.message !== "" && (
            <MessageBox
              message={response.message}
              variant={response.statusCode > 400 ? "danger" : "success"}
            />
          )}
          <span>{`Change ${props.name}'s password`}</span>
          <EditValue
            label="+ New Password"
            placeholder=""
            buttonLabel="Change"
            handleAdded={handleAdded}
            handlePasswordChange={handleChangePassword}
            id={props.id}
            functionType="change"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

interface ChangeUserRoleProps {
  name: string;
  id: number;
  role: string;
  handleEditUser(): void;
}

const ChangeUserRole: React.FC<ChangeUserRoleProps> = ({
  id,
  name,
  role,
  handleEditUser,
}) => {
  const [response, setResponse] = useState<serverResponse>({
    message: "",
    statusCode: 0,
  });

  const changeRole = async (id: number, role: string) => {
    let newRole = "";
    if (role === "admin") {
      newRole = "user";
    }
    if (role === "user") {
      newRole = "admin";
    }

    let resp = await editUserRole(id, newRole);

    setResponse(resp);
    handleEditUser();
    setTimeout(() => {
      setResponse({
        message: "",
        statusCode: 0,
      });
    }, 3000);
  };

  const generateSentence = (role: string, name: string): string => {
    let displayString = "";
    if (role === "admin") {
      displayString = `- Remove admin role from ${name}`;
    } else {
      displayString = `+ Make ${name} an admin user`;
    }
    return displayString;
  };

  return (
    <React.Fragment>
      <div>
        {response.message !== "" && (
          <MessageBox
            message={response.message}
            variant={response.statusCode > 400 ? "danger" : "success"}
          />
        )}
        <span
          className="edit-value"
          onClick={() => {
            changeRole(id, role);
          }}
        >
          {generateSentence(role, name)}
        </span>
      </div>
    </React.Fragment>
  );
};
