import { FC, useState } from "react";
import { CompatibilityDetails } from "../Compatibility.type";
import { CompatibilityBlock } from "./CompatibilityBlock/CompatibilityBlock";
import {
  GROWTH_ALLIES_SCORE,
  SUPPORTIVE_COLLABORATORS_SCORE,
  SYNERGISTIC_PARTNERS_SCORE,
} from "../Compatibility.const";
import { getUsersByCompatibility } from "../Compatibility.fn";

type Props = {
  users: CompatibilityDetails[];
  selectedUser?: CompatibilityDetails;
  setSelectedUser?: (user: CompatibilityDetails) => void;
};

export const CompatibilitySection: FC<Props> = ({
  users,
  selectedUser,
  setSelectedUser,
}) => {
  const synergisPartners = getUsersByCompatibility(
    users,
    (u) => u.score >= SYNERGISTIC_PARTNERS_SCORE
  );

  const supportiveCollaborators = getUsersByCompatibility(
    users,
    (u) =>
      u.score >= SUPPORTIVE_COLLABORATORS_SCORE &&
      u.score < SYNERGISTIC_PARTNERS_SCORE
  );

  const growthAllies = getUsersByCompatibility(
    users,
    (u) =>
      u.score >= GROWTH_ALLIES_SCORE && u.score < SUPPORTIVE_COLLABORATORS_SCORE
  );

  const conflictProne = getUsersByCompatibility(
    users,
    (u) => u.score < GROWTH_ALLIES_SCORE
  );

  const data = [
    { title: "Synergistic Partners", users: synergisPartners },
    { title: "Supportive Collaborators", users: supportiveCollaborators },
    { title: "Growth Allies", users: growthAllies },
    { title: "Conflict Prone", users: conflictProne },
  ];

  return (
    <div>
      {data.map((d) => (
        <CompatibilityBlock
          title={d.title}
          users={d.users}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      ))}
    </div>
  );
};
