import React, { FC } from "react";
import Html from "react-pdf-html";

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Svg,
  G,
  Path,
  Rect,
  Font,
  usePDF,
} from "@react-pdf/renderer";

import Logo from "../../../assets/media/logo-pdf.png";
import Background from "../../../assets/media/fingerprint-pdf2.png";
import TwoShapes from "../../../assets/media/lapin-two-shapes-pdf.png";
import Ginto from "../../../assets/fonts/GintoNormal-Light.woff";
import GintoBold from "../../../assets/fonts/GintoNormal-Medium.woff";

Font.register({
  family: "Ginto",
  src: Ginto,
  fontWeight: "light",
});

Font.register({
  family: "GintoBold",
  src: GintoBold,
  fontWeight: "ultrabold",
});

const styles = StyleSheet.create({
  h1: {
    fontSize: "20pt",
    lineHeight: "0pt",
    color: "#3C78E6",
  },

  h2: {
    marginTop: 20,
    fontSize: "14pt",
    lineHeight: "0pt",
    color: "#3C78E6",
  },

  text: {
    fontSize: "9pt",
    lineHeight: "2pt",
    color: "#1A1A42",
    fontFamily: "Ginto",
  },

  fewText: {
    fontSize: "10pt",
    lineHeight: "3pt",
    color: "#1A1A42",
    fontFamily: "Ginto",
  },

  boldText: {
    fontSize: "10pt",
    lineHeight: "2pt",
    color: "black",
    fontWeight: "bold",
    fontFamily: "GintoBold",
  },

  boldFewText: {
    fontSize: "11pt",
    lineHeight: "3pt",
    color: "black",
    fontWeight: "bold",
    fontFamily: "GintoBold",
  },

  values: {
    position: "absolute",
    top: 262,
    right: 250,
    width: 300,
  },

  value: {
    color: "#fff",
    fontSize: 13,
    textAlign: "right",
    marginTop: 3,
  },

  nameText: {
    fontSize: "10pt",
    lineHeight: "2pt",
    color: "white",
    fontFamily: "Ginto",
  },

  absoluteView: {
    position: "absolute",
    width: "160%",
    left: "0",
    top: "0",
    zIndex: "20",
  },

  relativePage: {
    position: "relative",
    padding: "20px",
    backgroundColor: "#1a1a42",
  },

  lowerText: {
    marginTop: "15pt",
    textAlign: "right",
  },

  lowerFewText: {
    marginTop: "60pt",
    textAlign: "right",
  },

  narrative: {
    position: "absolute",
    top: "65%",
    left: "20px",
    fontSize: "14px",
  },

  twoShapes: {
    position: "absolute",
    bottom: "30px",
    right: "20px",
    width: "35%",
  },

  p: {
    marginTop: 10,
    color: "white",
    fontSize: 12,
    lineHeight: 1.4,
  },

  accent: {
    backgroundColor: "#3c78e6",
  },
});

type Props = {
  userName: string;
  valueDrivers: string[];
  valuePhilosophy: string;
  archetype: string;
  archetypeExplanation: string;
  triggers: string[];
  triggersExplanation: string[];
};

export const PDFExport: FC<Props> = ({
  userName,
  valueDrivers,
  valuePhilosophy,
  archetype,
  archetypeExplanation,
  triggers,
  triggersExplanation,
}) => {
  return (
    <Document>
      <Page style={styles.relativePage}>
        <View style={{ width: "10%", height: "5%" }}>
          <Image src={Logo} />
        </View>

        <View style={styles.absoluteView}>
          <Image src={Background} style={{ borderColor: "transparent" }} />
        </View>

        <View>
          <View>
            <Text style={styles.p}>{userName}</Text>
            <Text style={styles.h1}>Your Leadership Fingerprint™</Text>
          </View>

          <View style={styles.values}>
            {valueDrivers?.map((v) => {
              return (
                <Text style={styles.value} key={v}>
                  {v}
                </Text>
              );
            })}
          </View>
        </View>

        <View style={styles.narrative}>
          <Text style={styles.h1}>Values Philosophy</Text>

          <Text style={styles.p}>{valuePhilosophy}</Text>
        </View>

        <View style={styles.twoShapes}>
          <Image
            src={TwoShapes}
            style={{ width: "100%", borderColor: "transparent" }}
          />
        </View>
      </Page>

      <Page style={styles.relativePage}>
        <View>
          <Text style={styles.p}>{userName}</Text>

          <Text style={styles.h1}>Your Defensive Archetype</Text>
          <Text style={styles.p}>
            Your Leadership Fingerprint™ represents who you are at your best. We
            all experience moments when we don't show up as our ideal selves.
            This is where understanding your Defensive Archetype becomes
            essential. Your Defensive Archetype describes how you behave when
            triggered into a defensive state. By identifying these behaviors and
            recognizing what triggers them, you can learn to manage and master
            your reactions. This awareness helps you align more closely with
            your values system and more consistently present your best self.
          </Text>

          <Text style={styles.h2}>Archetype:</Text>
          <Text style={styles.p}>{archetype}</Text>

          <Text style={styles.h2}>Archetype Explanation:</Text>
          <Text style={styles.p}>{archetypeExplanation}</Text>

          <Text style={styles.h2}>Triggers:</Text>
          <Text style={styles.p}>{triggers?.join(", ")}</Text>

          <Text style={styles.h2}>Triggers Explanation:</Text>

          {triggers?.map((t: any) => (
            <Text key={t} style={styles.p}>
              <Text style={styles.accent}>{t}</Text>: {triggersExplanation[t]}
            </Text>
          ))}
        </View>

        <Image
          src={TwoShapes}
          style={{
            width: 200,
            position: "absolute",
            bottom: 30,
            left: 20,
          }}
        />

        <Image
          src={Logo}
          style={{
            width: 60,
            position: "absolute",
            bottom: 30,
            right: 20,
          }}
        />
      </Page>
    </Document>
  );
};
