import { FC, useState } from "react";
import { CompatibilityDetails } from "../../Compatibility.type";
import "./CompatibilityBlock.scss";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

type Props = {
  title: string;
  users: CompatibilityDetails[];
  selectedUser?: CompatibilityDetails;
  setSelectedUser?: (user: CompatibilityDetails) => void;
};

export const CompatibilityBlock: FC<Props> = ({
  title,
  users,
  selectedUser,
  setSelectedUser,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div className="compat-block">
      <div className="compat-title" onClick={() => setOpen(!open)}>
        <div className="compat-decor" />

        <div className="compat-title-text">
          {title}

          <FontAwesomeIcon
            icon={faAngleDown}
            style={{
              position: "relative",
              top: 2,
              marginLeft: 8,
              fontSize: 20,
            }}
          />
        </div>
      </div>

      <div style={{ display: open ? "block" : "none" }}>
        {users.map((user) => {
          return (
            <div
              className={cn("compat-user", {
                selected: user.id === selectedUser?.id,
              })}
              key={user.id}
              onClick={() => setSelectedUser?.(user)}
            >
              <div>{user.name}</div>
              <div>{user.score}%</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
