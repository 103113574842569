import API from "../API";
import { IError, serverResponse } from "../dtos/error.dto";
import { IValueDto } from "../dtos/value.dto";

export const addValue = async (value: string): Promise<serverResponse> => {
  let response: serverResponse = { statusCode: 0, message: "" };
  try {
    const result = await API.post<IValueDto>("values/add-value", { value });
    response.statusCode = result.status;
    response.message = `Added Value.`;
  } catch (error: any) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};

export const editValue = async (
  id: number,
  value: string
): Promise<serverResponse> => {
  let response: serverResponse = { statusCode: 0, message: "" };

  try {
    const result = await API.post<IValueDto>("values/edit-value", {
      value: value,
      id: id,
    });
    response.statusCode = result.status;
    response.message = `Edited Value.`;
  } catch (error: any) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};

export const getValues = async () => {
  const result = await API.get(`values`);
  return result.data;
};

export const deleteValues = async (id: number): Promise<serverResponse> => {
  let response = { message: "", statusCode: 0 };
  try {
    const result = await API.delete(`values/delete-value/${id}`);
    response.statusCode = result.status;
    response.message = `Deleted Values.`;
  } catch (error: any) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};
