import { FormikProps, Form, withFormik } from "formik";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import * as Yup from "yup";

import { recoverPassword } from "../../../services/user.service";
import { MessageBox } from "../../helpers";
import { FormMessages } from "../../helpers/message-box/message-box";

interface FormValues {
  email: string;
  newPassword: string;
  confirmPassword: string;
  recoveryKey: string;
}

const InnerForm = (props: FormikProps<FormValues>) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    status,
  } = props;

  return (
    <Form noValidate>
      {status._errors && (
        <FormMessages variant="danger" messages={status._errors} />
      )}

      {status._success && (
        <FormMessages variant="success" messages={[`Password updated.`]} />
      )}

      <FormGroup>
        <FormLabel>Email :</FormLabel>
        <FormControl
          type="text"
          name="email"
          placeholder="Email"
          className={touched.email && errors.email ? "error" : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
        {touched.email && errors.email && (
          <div className="error-message">{errors.email}</div>
        )}
      </FormGroup>

      <FormGroup>
        <FormLabel>New Password :</FormLabel>
        <FormControl
          type="password"
          name="newPassword"
          placeholder="New Password"
          className={touched.newPassword && errors.newPassword ? "error" : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.newPassword}
        />
        {touched.newPassword && errors.newPassword && (
          <div className="error-message">{errors.newPassword}</div>
        )}
      </FormGroup>

      <FormGroup>
        <FormLabel>Confirm Password :</FormLabel>
        <FormControl
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          className={
            touched.confirmPassword && errors.confirmPassword ? "error" : ""
          }
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
        />
        {touched.confirmPassword && errors.confirmPassword && (
          <div className="error-message">{errors.confirmPassword}</div>
        )}
      </FormGroup>

      <FormGroup>
        <FormLabel>Key :</FormLabel>
        <FormControl
          type="text"
          name="recoveryKey"
          placeholder="Key"
          className={touched.recoveryKey && errors.recoveryKey ? "error" : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.recoveryKey}
        />
        {touched.recoveryKey && errors.recoveryKey && (
          <div className="error-message">{errors.recoveryKey}</div>
        )}
      </FormGroup>
      <span>This is your recovery key - please do not modify this value </span>
      <Button
        style={{ marginTop: "5rem" }}
        variant="primary"
        type="submit"
        disabled={isSubmitting}
      >
        Submit
      </Button>
    </Form>
  );
};

const OuterForm = withFormik<{ recoveryKey?: string | null }, FormValues>({
  mapPropsToStatus: (props) => {
    return {
      _errors: null,
      _success: false,
    };
  },

  mapPropsToValues: (props) => {
    return {
      email: "",
      newPassword: "",
      confirmPassword: "",
      recoveryKey: props.recoveryKey || "",
    };
  },

  validationSchema: () => {
    const schema = Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .email("Not a valid email address"),
      newPassword: Yup.string().required("New Password is a required field"),
      confirmPassword: Yup.string()
        .required("Confirm Password is a required field")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
      recoveryKey: Yup.string().required("Recovery key is a required field"),
    });

    return schema;
  },

  handleSubmit: async (values, { setSubmitting, setStatus }) => {
    try {
      await recoverPassword(
        values.email,
        values.newPassword,
        values.recoveryKey
      );
      setStatus({ _success: true });
    } catch (e: any) {
      setStatus({ _errors: e.messages });
    }
    setSubmitting(false);
  },
})(InnerForm);

export { OuterForm as RecoverPasswordForm };
