import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import "./Compatibility.scss";
import { CompatibilityChart } from "./CompatibilityChart/CompatibilityChart";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CompatibilityPDF } from "./CompatibilityPDF/CompatibilityPDF";
import { toCanvas, toPng } from "html-to-image";
import { useOrders } from "../../modules/order/order.fn";
import { useUsers } from "../../modules/user/user.query";
import { useCoupons } from "../../modules/coupon/coupon.query";
import { useNarrative } from "../../modules/narrative/narrative.fn";
import {
  getByCoupon,
  getUserIdsByCoupon,
} from "../../modules/coupon/coupon.fn";
import {
  useCompatibilityMatrix,
  useIndividualCompatibility,
} from "../../modules/deduction/deduction.fn";
import { LoginHeader } from "../../modules/landing/navigation/login-header";
import { Button } from "react-bootstrap";
import { CompatibilityDetails } from "./CompatibilityDetails/CompatibilityDetails";
import {
  CompatibilityDetails as CompatibilityDetailsType,
  CompatibilityLevel,
} from "./Compatibility.type";
import {
  COMPATIBILITY_COLORS,
  GROWTH_ALLIES_SCORE,
  SUPPORTIVE_COLLABORATORS_SCORE,
  SYNERGISTIC_PARTNERS_SCORE,
} from "./Compatibility.const";
import exportIcon from "../../assets/media/export-icon.svg";

function getBackground(c: number) {
  if (c >= SYNERGISTIC_PARTNERS_SCORE) {
    return COMPATIBILITY_COLORS[CompatibilityLevel.SynergisticPartners];
  }

  if (c >= SUPPORTIVE_COLLABORATORS_SCORE) {
    return COMPATIBILITY_COLORS[CompatibilityLevel.SupportiveCollaborators];
  }

  if (c >= GROWTH_ALLIES_SCORE) {
    return COMPATIBILITY_COLORS[CompatibilityLevel.GrowthAllies];
  }

  if (c === -1) {
    return COMPATIBILITY_COLORS[CompatibilityLevel.Yourself];
  }

  return COMPATIBILITY_COLORS[CompatibilityLevel.ConflictProne];
}

export const Compatibility: FC = () => {
  const chart = useRef<HTMLDivElement | null>(null);
  const table = useRef<HTMLDivElement | null>(null);

  const getImg = async (chart: HTMLDivElement) => {
    if (chart) {
      const src = await toPng(chart);
      setImg(src);
    }
  };

  const downloadImg = async () => {
    toCanvas(table.current as any).then((canvas) => {
      const link = document.createElement("a");
      link.download = "chart.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const [img, setImg] = useState("");

  const [selectedCoupons, setSelectedCoupons] = useState<
    { id: number; code: string }[]
  >([]);

  const [selectedUsers, setSelectedUsers] = useState<
    { id: number; name: string }[]
  >([]);

  const [selectedCompatibility, setSelectedCompatibility] = useState(
    [] as any[]
  );

  const { orders } = useOrders();

  const { data: users } = useUsers();

  const { data: coupons, isLoading: isLoadingCoupons } = useCoupons();

  const { data: narrativeData, isLoading: isLoadingNarraiveData } =
    useNarrative();

  const ids = getUserIdsByCoupon(
    orders,
    selectedCoupons.map((c) => c.id)
  );

  const usersByCoupon =
    selectedCoupons.length === 0
      ? users
      : users?.filter((u: any) => ids.includes(u.id));

  const usersSortedByFullName = usersByCoupon?.sort((a: any, b: any) => {
    const nameA = a?.fullName?.toLowerCase();
    const nameB = b?.fullName?.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const narrativeDataByCoupon = getByCoupon(narrativeData, ids);

  const compatibilityMatrixPayload = narrativeDataByCoupon
    // Find narrative data for the selected users
    ?.filter((d: any) => {
      const ids = selectedUsers.map((u) => u.id);

      return ids.includes(d.userId);
    })
    // Filter users with all narrative data
    ?.filter((d: any) => {
      return (
        d.userId &&
        d.rankedValues &&
        d.valuesPhilosophy &&
        d.archetype &&
        d.archetypeExplanation &&
        d.triggers &&
        d.triggersExplanation
      );
    })
    // Transform payload
    .map((d: any) => {
      return {
        id: d.userId,
        userId: d.userId,
        rankedValues: d.rankedValues,
        valuesPhilosophy: d.valuesPhilosophy,
        archetype: d.archetype,
        archetypeExplanation: d.archetypeExplanation,
        triggers: d.triggers,
        triggersExplanation: d.triggersExplanation,
      };
    })
    // Don't allow multiple narrative records, choose the latest
    ?.reduce((acc, cur) => {
      const hasThisUserAlready = !!acc.find((el) => el.userId === cur.userId);

      if (!hasThisUserAlready) {
        acc.push(cur);
      }

      return acc;
    }, [] as any[]);

  const targetUserForIndividualReport = users?.find(
    (u: any) =>
      u.id === selectedCompatibility?.find((d) => d.compatibility === -1)?.id
  );

  const selectedTableRowIndex = compatibilityMatrixPayload?.findIndex(
    (p: any) => p?.userId === targetUserForIndividualReport?.id
  );

  const {
    status,
    data: compatibilityMatrixData,
    isFetching: isLoadingCompatibilityMatrix,
    isError: isErrorCompatibilityMatrix,
    refetch: fetchCompatibilityMatrix,
  } = useCompatibilityMatrix({
    workGroupData: compatibilityMatrixPayload,
    users: compatibilityMatrixPayload?.length || 0,
  });

  const compatibilityMatrix = compatibilityMatrixData?.matrix;

  const individualReportPayload = useMemo(
    () =>
      compatibilityMatrixPayload?.map((mp) => {
        // Find the target user compatibilities from the compatibility matrix
        const compatibilities = compatibilityMatrix?.find(
          (m: any) => !!m[targetUserForIndividualReport?.id]
        )?.[targetUserForIndividualReport?.id];

        // Get the compatibility score for each user in the individual report payload
        const compatibility_score = compatibilities?.find(
          (c: any) => c?.id === mp?.userId
        )?.compatibility;

        return { ...mp, compatibility_score };
      }),
    [
      compatibilityMatrix,
      compatibilityMatrixPayload,
      targetUserForIndividualReport,
    ]
  );

  const {
    data: individualCompatibility,
    isLoading: isLoadingIndividualCompatibility,
  } = useIndividualCompatibility(
    {
      target_user_id: targetUserForIndividualReport?.id,
      data: individualReportPayload || [],
    },
    targetUserForIndividualReport
  );

  const compatibilityByUser = Object.entries(
    individualCompatibility?.compatibility || {}
  )
    ?.map(([key, value]: any) => {
      const score = value?.score;
      const description = value?.compatibility_description;

      const user = usersByCoupon?.find((f: any) => f.id == key);

      return { id: user?.id, name: user?.fullName, score, description };
    })
    ?.sort((a, b) => b.score - a.score);

  const USERS: CompatibilityDetailsType[] = [
    { id: 0, name: "User 1", score: 18, description: "Description 1" },
    { id: 1, name: "User 2", score: 26, description: "Description 2" },
    { id: 2, name: "User 3", score: 56, description: "Description 3" },
    { id: 3, name: "User 4", score: 78, description: "Description 4" },
    { id: 4, name: "User 5", score: 90, description: "Description 5" },
  ];

  const selectedUserForIndividualReport = users?.find(
    (u: any) =>
      u.id === selectedCompatibility?.find((d) => d.compatibility === -1)?.id
  );

  useEffect(() => {
    if (chart.current) {
      getImg(chart.current);
    }
  }, [chart.current, compatibilityByUser]);

  return (
    <div>
      <div className="compatibility-wrapper">
        {/* Login */}
        <LoginHeader />

        {/* Filters */}
        <div>
          <div className="dashboard-panel">
            <label>
              <span className="filter-label">Filter by coupon:</span>

              <select
                onChange={(e) => {
                  const code = e.target.value;

                  const coupon = coupons?.find((c) => c.code === code);

                  // Clear filter when selecting "All" groups
                  if (code === "All") {
                    setSelectedCoupons([]);

                    return;
                  }

                  // Early return undefined
                  if (!coupon) {
                    return;
                  }

                  // Coupon already in filters
                  if (selectedCoupons.map((c) => c.code).includes(code)) {
                    return;
                  }

                  setSelectedCoupons([...selectedCoupons, coupon]);
                }}
              >
                <option key={0} value="All">
                  {isLoadingCoupons ? "Loading..." : "All"}
                </option>

                {coupons?.map((c) => (
                  <option key={c.id} value={c.code}>
                    {c.code}
                  </option>
                ))}
              </select>
            </label>

            {!!selectedCoupons.length && (
              <div className="dashboard-coupons">
                <span className="filter-label">Selected groups:</span>

                {selectedCoupons.map((coupon) => (
                  <div
                    key={coupon.id}
                    className="dashboard-coupon"
                    onClick={() => {
                      const [...coupons] = selectedCoupons;

                      const index = coupons.findIndex(
                        (c) => c.id === coupon.id
                      );

                      coupons.splice(index, 1);

                      setSelectedCoupons(coupons);
                    }}
                  >
                    {coupon.code} <span>&#10060;</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="dashboard-panel">
            <label>
              <span className="filter-label">Select users:</span>

              <select
                onChange={(e) => {
                  const name = e.target.value;

                  const user = usersByCoupon?.find(
                    (u: any) => u.fullName === name
                  );

                  if (!user) {
                    return;
                  }

                  if (!selectedUsers.find((u) => u.id === user.id)) {
                    setSelectedUsers([
                      { id: user.id, name: user.fullName },
                      ...selectedUsers,
                    ]);
                  }
                }}
              >
                <option key={0} value="All">
                  {isLoadingCoupons ? "Loading..." : "All"}
                </option>

                {usersSortedByFullName?.map((u: any) => {
                  const narrative = narrativeDataByCoupon?.find(
                    (_: any) => _.userId === u?.id
                  );

                  return (
                    <option
                      key={u.id}
                      value={u.fullName}
                      disabled={!narrative}
                      title={
                        isLoadingNarraiveData
                          ? "Loading..."
                          : !narrative
                          ? "This user doesn't have Lapin 2.0 narrative"
                          : ""
                      }
                    >
                      {u.fullName}
                    </option>
                  );
                })}
              </select>
            </label>

            <div style={{ display: "flex", alignItems: "center" }}>
              {selectedUsers.map((u) => (
                <div key={u.id} style={{ marginLeft: 10 }}>
                  {u.name},
                </div>
              ))}
            </div>
          </div>

          <div className="dashboard-panel">
            <Button size="lg" onClick={() => fetchCompatibilityMatrix()}>
              {isLoadingCompatibilityMatrix ? "Loading..." : "Get"}{" "}
              Compatibility Matrix
            </Button>

            <Button
              size="lg"
              style={{ marginLeft: 20 }}
              onClick={() => {
                setSelectedUsers([]);
                setSelectedCompatibility([]);
              }}
            >
              Reset Users
            </Button>
          </div>
        </div>
      </div>

      {/* Chart */}
      <div className="compatibility-section blue">
        <div className="compatibility-wrapper">
          <h1>Compatibility</h1>

          <div id="compatibility" className="compatibility-grid">
            <div ref={chart}>
              <CompatibilityChart
                users={compatibilityByUser}
                selectedUser={selectedUserForIndividualReport?.fullName}
                isLoading={
                  isLoadingIndividualCompatibility ||
                  isLoadingCompatibilityMatrix ||
                  compatibilityByUser.length === 0
                }
              />
            </div>

            <CompatibilityDetails
              users={compatibilityByUser}
              selectedUserForIndividualReport={
                selectedUserForIndividualReport?.fullName
              }
              compatibilityByUser={compatibilityByUser}
              compatibilityGraph={img}
              isLoadingCompatibility={isLoadingIndividualCompatibility}
            />
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="compatibility-section">
        <div className="compatibility-wrapper">
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: 21,
            }}
          >
            Compatibility Matrix:
            <button className="btn btn-primary btn-lg" onClick={downloadImg}>
              Export <img src={exportIcon} />
            </button>
          </h2>

          {isErrorCompatibilityMatrix && (
            <div>
              We couldn't generate the Compatibility Matrix. Please, try to
              generate it again, or select different users.
            </div>
          )}

          {!compatibilityMatrix &&
            !isLoadingCompatibilityMatrix &&
            !isErrorCompatibilityMatrix && (
              <div>
                Please, select the users in the filter section and click "Get
                Compatibility Matrix" button.
              </div>
            )}

          {isLoadingCompatibilityMatrix && <div>Loading...</div>}

          {compatibilityMatrix && (
            <div ref={table}>
              <table className="compatibility-table">
                <tbody>
                  <tr key={24324}>
                    <td></td>
                    {Object.keys(compatibilityMatrix)?.map((key: any) => {
                      const c = Object.entries(compatibilityMatrix[key]);

                      const currentUserId = c[0][0];

                      const userData: any = usersByCoupon?.find(
                        (f: any) => f.id == currentUserId
                      );

                      return <td key={userData.id}>{userData.fullName}</td>;
                    })}
                  </tr>

                  {Object.keys(compatibilityMatrix)?.map((key: any, i) => {
                    const c = Object.entries(compatibilityMatrix[key]);

                    const currentUserId = c[0][0];

                    const userData: any = usersByCoupon?.find(
                      (f: any) => f.id == currentUserId
                    );

                    const data: any = c[0][1];

                    return (
                      <tr
                        key={`${userData.id}-${currentUserId}`}
                        className={cn([
                          { "table-row-selected": selectedTableRowIndex === i },
                        ])}
                        onClick={() => {
                          setSelectedCompatibility(data);
                        }}
                      >
                        {data?.map((el: any, i: number) => {
                          return i === 0 ? (
                            <>
                              <td key={`${el.id}-1`}>{userData.fullName}</td>
                              <td
                                key={`${el.id}-2`}
                                style={{
                                  background: getBackground(el.compatibility),
                                  textAlign: "center",
                                  color: "white",
                                }}
                                title={`Compatibility: ${el.compatibility}`}
                              >
                                {el.compatibility > 0
                                  ? `${el.compatibility}%`
                                  : null}
                              </td>
                            </>
                          ) : (
                            <td
                              key={`${el.id}-1`}
                              style={{
                                background: getBackground(el.compatibility),
                                textAlign: "center",
                                color: "white",
                              }}
                              title={`Compatibility: ${el.compatibility}`}
                            >
                              {el.compatibility > 0
                                ? `${el.compatibility}%`
                                : null}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
