import { FormikProps, Form, withFormik } from "formik";
import { connect, ConnectedProps } from "react-redux";
import * as Yup from "yup";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";

import { MessageBox } from "../../helpers";
import { userActions } from "../../../actions/login.action";
import { RootState } from "../../../app/store";
import { ArrowIcon } from "../../../Icons";
import { Link } from "react-router-dom";

import "./login-page";
import { FormMessages } from "../../helpers/message-box/message-box";

const mapState = (state: RootState) => {
  const { _processing, _errors } = state.authentication;
  return { _processing, _errors };
};

const actionCreators = {
  login: userActions.login,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface LoginFormValues {
  email: string;
  password: string;
}

interface OtherProps extends PropsFromRedux {
  message?: string;
}

const InnerLoginForm = (props: OtherProps & FormikProps<LoginFormValues>) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    _errors,
    _processing,
  } = props;

  return (
    <Form noValidate>
      {_errors && <FormMessages variant="danger" messages={_errors} />}

      <FormGroup>
        <FormLabel>Email</FormLabel>
        <FormControl
          type="text"
          name="email"
          placeholder="Email..."
          className={touched.email && errors.email ? "error" : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
        {touched.email && errors.email && (
          <div className="error-message">{errors.email}</div>
        )}
      </FormGroup>

      <FormGroup>
        <FormLabel>Password</FormLabel>
        <FormControl
          type="password"
          name="password"
          placeholder="Password..."
          className={touched.password && errors.password ? "error" : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
        />
        {touched.password && errors.password && (
          <div className="error-message">{errors.password}</div>
        )}
      </FormGroup>

      {_processing && <div>Loading</div>}
      <div className="login-button-flex">
        <span>
          <Link to="/forgot-password">Forgot Password?</Link>
        </span>

        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Login
          <ArrowIcon direction="left" />
        </Button>
      </div>
    </Form>
  );
};

interface LoginFormProps extends PropsFromRedux {
  email?: string;
  message?: string;
}

const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
  // Transform outer props into form values
  mapPropsToValues: (props) => {
    return {
      email: props.email || "admin@lapin.com", //TODO: remove this and set to blank
      password: "admin", //TODO: remove this and set to blank
    };
  },

  // Add a custom validation function (this can be async too!)
  validationSchema: () => {
    const schema = Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .email("Not a valid email address"),
      password: Yup.string().required("Password is a required field"),
    });

    return schema;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    const { login } = props;
    login(values.email, values.password);
    setSubmitting(false);
  },
})(InnerLoginForm);

const connectedLoginPage = connector(LoginForm);
export { connectedLoginPage as LoginForm };
