import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "./app/store";
import "./App.scss";
import { LandingPage } from "./modules/landing/landing-page";
import { UserManagementPage } from "./modules/landing/user-management/user-management-page";
import { NavigationBar } from "./modules/landing/navigation/navigation-bar";
import { StartPage } from "./modules/landing/start-page/start-page";
import { ForgotPasswordPage } from "./modules/landing/forgot-password/forgot-password-page";
import { SingleUserPage } from "./modules/landing/user-management/user-management-single-user/user-management-single-user";
import { ValuesLookUp } from "./modules/landing/values/ValuesLookUp";
import { CouponManagementPage } from "./modules/landing/coupon-management/coupon-management-page";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import { Compatibility } from "./screens/Compatibility/Compatibility";
import { IndividualReport } from "./screens/IndividualReport/IndividualReport";
const mapState = (state: RootState) => {
  const { authenticated } = state.authentication;
  return { authenticated };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

const App: React.FC<PropsFromRedux> = (props) => {
  const { authenticated } = props;

  if (authenticated) {
    return (
      <div className="App">
        <Router>
          <NavigationBar />

          <Switch>
            <Route path="/user-management/:id">
              <SingleUserPage />
            </Route>

            <Route exact path="/user-management">
              <UserManagementPage />
            </Route>

            <Route exact path="/coupon-management">
              <CouponManagementPage />
            </Route>

            <Route exact path="/values-management">
              <ValuesLookUp />
            </Route>

            <Route exact path="/dashboard">
              <Dashboard />
            </Route>

            <Route exact path="/compatibility">
              <Compatibility />
            </Route>

            <Route exact path="/individual-report">
              <IndividualReport />
            </Route>

            <Route path="/forgot-password">
              <ForgotPasswordPage />
            </Route>

            <Route path="/">
              <StartPage />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/auth">
              <LandingPage />
            </Route>
            <Route path="/forgot-password">
              <ForgotPasswordPage />
            </Route>
            <Redirect from="/" to="/auth" />
          </Switch>
        </Router>
      </div>
    );
  }
};

const connectedApp = connector(App);
export { connectedApp as App };
