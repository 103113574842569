import { ForgotPasswordForm } from "./forgot-password-form";
import "../login/login-page.scss";

export const ForgotPasswordPage: React.FC<{}> = () => {
  return (
    <div className="forgot-password-wrapper-div">
      <div className="forgot-password-flex-wrapper">
        <span className="login-lapin-header">
          {`Lapin Leadership Fingerprint${String.fromCodePoint(
            parseInt("2122", 16)
          )}
           Admin Dashboard`}
        </span>
        <div className="login-center-div">
          <span className="forgot-password-page-title">Forgot Password</span>
        </div>
        <ForgotPasswordForm />
      </div>
    </div>
  );
};
