import React from "react";

export function ArrowIcon(props: any) {
  return (
    <span className={`icon icon-arrow icon-arrow-${props.direction}`}>
      <svg width="16.103" height="10.649" viewBox="0 0 16.103 10.649">
        <g id="Group_87" transform="translate(0 0.707)">
          <path
            id="Path_16"
            d="M287.641,384l4.618,4.618-4.618,4.618"
            transform="translate(-277.57 -384)"
            fill="none"
            stroke={props.color}
            strokeWidth="2"
          />
          <path
            id="Path_35"
            d="M124,21.617h14.088"
            transform="translate(-124 -17)"
            fill="none"
            stroke={props.color ? props.color : "#D0D3D6"}
            strokeWidth="2"
          />
        </g>
      </svg>
    </span>
  );
}

export function LinkIcon(props: any) {
  return (
    <span className={`icon icon-link`}>
      <svg width="16.669" height="16.669" viewBox="0 0 16.669 16.669">
        <g id="link-icon" transform="translate(0 0)">
          <path
            id="Path_700"
            d="M6.1,13.225l-.843.838a1.887,1.887,0,0,1-2.651,0,1.834,1.834,0,0,1,0-2.608l3.1-3.08c.643-.638,1.854-1.577,2.736-.7A1.033,1.033,0,1,0,9.906,6.206c-1.5-1.488-3.716-1.213-5.646.7l-3.1,3.08a3.9,3.9,0,0,0,0,5.539,3.959,3.959,0,0,0,5.561,0l.844-.838A1.033,1.033,0,0,0,6.1,13.225ZM15.513,1.259a3.689,3.689,0,0,0-5.354-.205L9.108,2.1a1.033,1.033,0,1,0,1.456,1.466L11.615,2.52a1.673,1.673,0,0,1,2.443.205,1.833,1.833,0,0,1,0,2.608L10.746,8.619c-1.515,1.5-2.225.8-2.528.5a1.033,1.033,0,1,0-1.455,1.466,3.257,3.257,0,0,0,2.321,1.032A4.548,4.548,0,0,0,12.2,10.085L15.514,6.8a3.9,3.9,0,0,0,0-5.54Z"
            fill={props.color ? props.color : "#D0D3D6"}
          />
        </g>
      </svg>
    </span>
  );
}

export function CloseIcon(props: any) {
  return (
    <span className={`icon icon-close`}>
      <svg width="13" height="13" viewBox="0 0 15.703 15.703">
        <g id="Group_97" transform="translate(-142.762 -828.095) rotate(45)">
          <line
            id="Line_2"
            y2="16.207"
            transform="translate(697.604 476.5)"
            fill="none"
            stroke={props.color ? props.color : "#D0D3D6"}
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_3"
            y2="16.207"
            transform="translate(705.707 484.604) rotate(90)"
            fill="none"
            stroke={props.color ? props.color : "#D0D3D6"}
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </span>
  );
}

export function AngleIcon(props: any) {
  return (
    <svg width="7.336" height="13.259" viewBox="0 0 7.336 13.259">
      <path
        id="Path_52"
        data-name="Path 52"
        d="M5406.406,616l6.276,6.276-6.276,6.276"
        transform="translate(-5406.053 -615.646)"
        fill="none"
        stroke="#3c78e6"
        strokeWidth="1"
      />
    </svg>
  );
}

export function BoldAngleIcon(props: any) {
  return (
    <svg width="7.591" height="13.061" viewBox="0 0 7.591 13.061">
      <path
        id="Arrow"
        d="M2903,14.366l6,6-6,6"
        transform="translate(-2902.47 -13.836)"
        fill="none"
        stroke="#3c78e6"
        strokeWidth="1.5"
      />
    </svg>
  );
}
