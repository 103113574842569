import { FC } from "react";
import exportIcon from "../../../assets/media/export-icon.svg";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CompatibilityPDF } from "../CompatibilityPDF/CompatibilityPDF";

type Props = {
  name: string | undefined;
  description: string | undefined;
  compatibilityByUser: any[];
  compatibilityGraph: string;
  selectedUserForIndividualReport: string;
  isLoading: boolean;
};

export const CompatibilityDescription: FC<Props> = ({
  name,
  description,
  compatibilityByUser,
  compatibilityGraph,
  selectedUserForIndividualReport,
  isLoading,
}) => {
  return (
    <div
      style={{
        paddingLeft: 30,
        borderLeft: "2px solid #2d2e53",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
          fontSize: 24,
        }}
      >
        {name || ""}

        {!!compatibilityByUser.length && (
          <PDFDownloadLink
            document={
              <CompatibilityPDF
                users={compatibilityByUser}
                img={compatibilityGraph}
                userName={selectedUserForIndividualReport}
              />
            }
            fileName="Individual-Report.pdf"
          >
            {() => (
              <button
                className="btn btn-primary btn-lg"
                style={{ marginLeft: 10 }}
              >
                PDF <img src={exportIcon} />
              </button>
            )}
          </PDFDownloadLink>
        )}
      </div>

      <div>
        {isLoading
          ? "Loading..."
          : description ||
            "Please, select a user from the Compatibility Matrix"}
      </div>
    </div>
  );
};
