import API from "../API";
import SessionService from "./session.service";
import { ISessionUserDto } from "../dtos/session-user.dto";
import { AxiosRequestConfig } from "axios";
import { IOrderDto } from "../dtos/order.dto";
import { PaginatedResultDto } from "../dtos/paginated-result.dto";
import { ILicenseDto } from "../dtos/license.dto";
import { IBasicInfo } from "../modules/landing/user-management/user-management-single-user/user-management-single-user";
import { IError, serverResponse } from "../dtos/error.dto";

export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",
};

export interface IUserLoginReturnDto {
  access_token: string;
}

export const login = async (
  email: string,
  password: string
): Promise<ISessionUserDto> => {
  const result = await API.post<IUserLoginReturnDto>("/auth/login", {
    email: email,
    password: password,
  });
  const accessToken = result.data.access_token;

  const user = await getProfile(accessToken);
  SessionService.getInstance().setToken(accessToken);
  SessionService.getInstance().setUser(user);

  return user;
};

export const getProfile = async (
  accessToken?: string
): Promise<ISessionUserDto> => {
  const config: AxiosRequestConfig = {};

  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const result = await API.get<ISessionUserDto>("/users/me", config);
  return result.data;
};

export const logout = (): boolean => {
  SessionService.getInstance().clear();
  return true;
};

export const requestPasswordLink = async (email: string): Promise<boolean> => {
  const result = await API.post<boolean>("/users/request-recover-password", {
    email,
    url: process.env.REACT_APP_ADMIN_URL,
  });

  return result.data;
};

export const recoverPassword = async (
  email: string,
  newPassword: string,
  key: string
): Promise<boolean> => {
  const result = await API.post<boolean>("/users/recover-password", {
    email,
    newPassword,
    key,
  });

  return result.data;
};

export const getAllUsers = async (
  limit: number,
  offset: number,
  search: string
): Promise<{count: number, result: Array<ISessionUserDto>}> => {
  const result = await API.get("/users/", { params: { limit, offset, search } });
  return result.data;
};

export const getUser = async (id: string): Promise<ISessionUserDto> => {
  const result = await API.get(`/users/${id}`);

  return result.data;
};

export const getOrders = async (id: string) => {
  const result = await API.get<PaginatedResultDto<IOrderDto>>(
    `/users/orders/${Number(id)}`
  );
  return result.data;
};

export const getLicenses = async (id: string) => {
  const result = await API.get<PaginatedResultDto<ILicenseDto>>(
    `users/licenses/${Number(id)}`
  );
  return result.data;
};

export const createLicense = async (id: string) => {
  const result = await API.post<IOrderDto>(
    `users/create-license/${Number(id)}`
  );
  return result.data;
};

export const changePassword = async (
  id: number,
  password: string
): Promise<serverResponse> => {
  let response: serverResponse = { statusCode: 0, message: "" };
  try {
    const result = await API.post(`users/change-password/${id}`, { password });
    response.statusCode = result.status;
    response.message = "Changed password succesfully";
  } catch (error) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};

export const saveUserInfo = async (
  id: number,
  info: IBasicInfo
): Promise<serverResponse> => {
  let response: serverResponse = { statusCode: 0, message: "" };
  try {
    const result = await API.post(`users/update-info/${id}`, { info });
    response.statusCode = result.status;
    response.message = "Updated user information succesfully.";
  } catch (error) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};

export const deleteUser = async (id: number): Promise<serverResponse> => {
  let response = { message: "", statusCode: 0 };
  try {
    const result = await API.delete(`users/delete/${id}`);
    response.message = `User was deleted successfully.`;
    response.statusCode = result.status;
  } catch (error) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};

export const editUserRole = async (id: number, role: string) => {
  let response = { message: "", statusCode: 0 };
  try {
    const result = await API.post(`users/update-role/${id}`, { role });
    response.message = "User's role was updated successfully.";
    response.statusCode = result.status;
  } catch (error) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};
