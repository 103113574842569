import API from "../API";
import { ICouponDto } from "../dtos/coupon.dto";
import { serverResponse, IError } from "../dtos/error.dto";

export const deleteCoupon = async (id: number): Promise<serverResponse> => {
  let response = { message: "", statusCode: 0 };
  try {
    const result = await API.delete(`/coupons/${id}`);
    response.message = `Coupon was deleted successfully.`;
    response.statusCode = result.status;
  } catch (error) {
    response.message = (error as IError).messages[0];
    response.statusCode = (error as IError).statusCode;
  }
  return response;
};

export const patchCoupon = async (id: number, coupon: Partial<ICouponDto>) => {
    let response = { message: "", statusCode: 0 };
    try {
        const result = await API.patch(`/coupons/${id}`, { enabled: coupon.enabled });
        response.message = "Coupon was updated successfully.";
        response.statusCode = result.status;
    } catch (error) {
        response.message = (error as IError).messages[0];
        response.statusCode = (error as IError).statusCode;
    }
    return response;
};

export const createCoupon = async (coupon: Partial<ICouponDto>) => {
    const result = await API.post<ICouponDto>("/coupons", { code: coupon.code });
    return result.data;
};

export const getAllCoupons = async (
): Promise<Array<ICouponDto>> => {
  const result = await API.get("/coupons/");
  return result.data;
};