import { LoginForm } from "./login-form";

import "./login-page.scss";

export const LoginPage: React.FC<{}> = () => {
  //let copy = React.string({js|\u00a9|js});
  return (
    <div className="login-main-wrapper-div">
      <div className="login-flex-wrapper">
        <span className="login-lapin-header">
          {`Lapin Leadership Fingerprint${String.fromCodePoint(
            parseInt("2122", 16)
          )}
           Admin Dashboard`}
        </span>
        <div className="form-centered-div">
          <span className="login-page-title">Admin Login</span>
          <LoginForm message="test" />
        </div>
      </div>
    </div>
  );
};
