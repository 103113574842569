import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions, RootState } from "../app/store";
import { serverResponse } from "../dtos/error.dto";

export const useErrorHandler = () => {
  // const {globalError, setGlobalError } = useContext(errorContext);
  const dispatch = useDispatch();
  const [httpError, setHttpError] = useState<serverResponse>({
    message: "",
    statusCode: 0,
  });
  const selectorError = useSelector((state: RootState) => {
    return state.editHttpError;
  });

  const updateHttpError = (response: serverResponse, seconds: number = 6) => {
    setHttpError(response);
    // dispatch(
    //   allActions.errorActions.updateHttpError(
    //     response.message,
    //     response.statusCode
    //   )
    // );
    // setTimeout(() => {
    //   dispatch(allActions.errorActions.updateHttpError("", 0));
    // }, seconds * 1000);
    setTimeout(() => {
      setHttpError({ message: "", statusCode: 0 });
    }, seconds * 1000);
  };

  useEffect(() => {
    if (httpError.statusCode === 401) {
      dispatch(allActions.userActions.logout());
    }
  }, [httpError]);

  return { httpError, selectorError, updateHttpError };
};
