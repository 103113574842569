import { serverResponse } from "../dtos/error.dto";

export const errorConstants = {
  ERROR_OCCURRED: "ERROR_OCCURRED",
};

const initialHttpError: serverResponse = { message: "", statusCode: 0 };

export const editHttpError = (state = initialHttpError, action: any) => {
  switch (action.type) {
    case errorConstants.ERROR_OCCURRED:
      return { message: action.message, statusCode: action.statusCode };
    default:
      return state;
  }
};

const updateHttpError = (message: string, statusCode: number) => {
  let action = {
    type: errorConstants.ERROR_OCCURRED,
    message: message,
    statusCode: statusCode,
  };
  return (dispatch: any) => {
    dispatch(action);
  };
};

export const errorActions = {
  updateHttpError,
};
