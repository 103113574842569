import React, { useEffect, useState } from "react";

import { Link, useRouteMatch } from "react-router-dom";
import { LoginHeader } from "../navigation/login-header";
import { deleteUser, getAllUsers } from "../../../services/user.service";

import "./user-management-page.scss";
import { ISessionUserDto } from "../../../dtos/session-user.dto";
import {
  DeleteValueModal,
  MessageBox,
} from "../../helpers/message-box/message-box";
import { statusToVariant } from "../../../dtos/error.dto";
import { useErrorHandler } from "../../../hooks/error-hook";
import { Layout } from "../../../components/Layout/Layout";
export const UserManagementPage: React.FC<{}> = () => {
  const [current_page, setCurrentPage] = useState(1);
  const [display_array, setDisplayArray] = useState<Array<ISessionUserDto>>([]);
  const [total_pages, setTotalPages] = useState(1);
  const [total_users_count, setTotalUsersCount] = useState(0);
  // const [total_users, setTotalUsers] = useState<Array<ISessionUserDto>>([]);
  const [search_input, setSearchInput] = useState("");
  const [search, setSearch] = useState<Array<ISessionUserDto>>([]);
  const [deleteArray, setDeleteArray] = useState<Array<number>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);
  const { httpError, updateHttpError } = useErrorHandler();

  const posts_per_page = 25;

  useEffect(() => {
    setTotalPages(Math.ceil(total_users_count / posts_per_page));
  }, [total_users_count]);

  const fetchAPI = async () => {
    try {
      const response = await getAllUsers(3000, 0, search_input);

      setDisplayArray([...response.result]);
      setTotalUsersCount(response.count);
    } catch (err) {
      updateHttpError({
        message: "Could not retreive Users. Please try again later.",
        statusCode: 400,
      });
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(total_users_count / posts_per_page));
  }, [total_users_count]);

  useEffect(() => {
    fetchAPI();
  }, [current_page, added]);

  useEffect(() => {
    selectPosts([...search]);
  }, [search]);

  const selectPosts = (posts: ISessionUserDto[]) => {
    const temp_array = posts.filter((element, index) => {
      if (current_page > 1) {
        if (
          index >= (current_page - 1) * posts_per_page &&
          index <= current_page * posts_per_page
        ) {
          return element;
        }
      } else {
        if (index < posts_per_page) {
          return element;
        }
      }
    });
    setDisplayArray([...temp_array]);
  };

  const changePage = (direction: string) => {
    if (direction === "left") {
      if (current_page > 1) {
        setCurrentPage(current_page - 1);
      }
    } else if (direction === "right") {
      if (current_page < total_pages) {
        setCurrentPage(current_page + 1);
      }
    }
  };

  const handleInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const submitSearch = async () => {
    setCurrentPage(1);
    await fetchAPI();
  };

  const handleCheck = (id: number, checked: boolean) => {
    let newChecks = [...deleteArray];
    if (!checked) {
      if (newChecks.includes(id)) {
        newChecks = newChecks.filter((check) => check !== id);
      } else {
        newChecks.push(id);
      }
    } else {
      newChecks = newChecks.filter((check) => check != id);
    }

    setDeleteArray([...newChecks]);
  };

  const selectAll = (checked: boolean) => {
    let newChecks = [...deleteArray];
    if (checked) {
      display_array.map((value, index) => {
        if (!deleteArray.includes(parseInt(value.id))) {
          newChecks.push(parseInt(value.id));
        }
      });
    } else {
      newChecks = [];
    }

    setDeleteArray([...newChecks]);
  };

  const deleteUsers = async () => {
    Promise.all(
      deleteArray.map((value, index) => {
        return deleteUser(value);
      })
    ).then((value) => {
      value.map((value, index) => {
        if (value.statusCode > 399) {
          updateHttpError({
            message: value.message,
            statusCode: value.statusCode,
          });
        }
      });
      setDeleteArray([]);
      setOpenModal(false);
      setAdded(!added);
      setTimeout(() => {
        updateHttpError({ message: "", statusCode: 0 });
      }, 3000);
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Layout>
      {openModal && (
        <DeleteValueModal
          category={"user"}
          value_ids={deleteArray}
          onClose={handleCloseModal}
          onDelete={deleteUsers}
        />
      )}
      <TitleRow
        title={"User Management"}
        input={search_input}
        handleChange={handleInputChange}
        submitFunction={submitSearch}
        placeholder={"Search users..."}
      />
      {httpError.statusCode > 399 && (
        <MessageBox
          message={httpError.message}
          variant={statusToVariant(httpError.statusCode)}
        />
      )}
      <form className="user-management-form">
        <Table
          data={display_array
            .slice(
              total_users_count -
                posts_per_page -
                (current_page - 1) * posts_per_page,
              total_users_count - (current_page - 1) * posts_per_page
            )
            .reverse()}
          handleCheck={handleCheck}
          deleteArray={deleteArray}
          selectAll={selectAll}
        />
      </form>
      {deleteArray.length > 0 && (
        <span
          onClick={() => {
            setOpenModal(true);
          }}
          id="delete"
        >
          Delete
        </span>
      )}
      <Pagination
        total_pages={total_pages}
        current_page={current_page}
        total_users={total_users_count}
        users_per_page={posts_per_page}
        changePage={changePage}
      />
    </Layout>
  );
};

export interface TitleRowProps {
  title: string;
  input: string;
  handleChange(e: any): any;
  submitFunction(): any;
  placeholder: string;
}

export const TitleRow: React.FC<TitleRowProps> = ({
  title,
  input,
  handleChange,
  submitFunction,
  placeholder,
}) => {
  return (
    <div className="title-row-flex-wrapper">
      <h1 className="title">{title}</h1>
      <div className="search-div">
        <input
          type="text"
          placeholder={placeholder}
          value={input}
          onChange={handleChange}
        ></input>
        <button onClick={submitFunction}>Search</button>
      </div>
    </div>
  );
};

interface TableProps {
  data: ISessionUserDto[];
  deleteArray: Array<number>;
  handleCheck(id: number, checked: boolean): any;
  selectAll(checked: boolean): any;
}
export const Table: React.FC<TableProps> = (props) => {
  const [checkedAll, setCheckedAll] = useState(false);

  const handleCheckedAll = () => {
    setCheckedAll(!checkedAll);
  };

  useEffect(() => {
    props.selectAll(checkedAll);
  }, [checkedAll]);
  useEffect(() => {
    setCheckedAll(false);
  }, [props.data]);

  return (
    <div style={{ overflowX: "auto" }}>
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                id="selectall"
                onChange={handleCheckedAll}
                checked={checkedAll}
              />
            </th>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>ROLE</th>
            <th>ORDER COUNT</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((value, index) => {
            return (
              <TableRow
                name={value.fullName}
                email={value.email}
                role={value.roles}
                //role={value.roles.map((value, index)=>{return()})}
                checked={props.deleteArray.includes(parseInt(value.id))}
                style={index == props.data.length - 1 ? "" : "user"}
                head={false}
                key={index + "user"}
                id={value.id}
                orderCount={value.orderCount}
                handleCheck={props.handleCheck}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

type Role = {
  id: string;
  userId: string;
  type: string;
};

interface TableRowProps {
  name: string;
  email: string;
  checked: boolean;
  role: Array<Role>;
  style: string;
  head: boolean;
  id: string;
  orderCount: number;
  handleCheck(id: number, checked: boolean): any;
}

const TableRow: React.FC<TableRowProps> = ({
  name,
  email,
  checked,
  role,
  style,
  head,
  id,
  orderCount,
  handleCheck,
}) => {
  const match = useRouteMatch();

  const Checked = () => {
    handleCheck(parseInt(id), checked);
  };

  return (
    <tr>
      <td>
        <input
          onChange={() => {
            Checked();
          }}
          type="checkbox"
          checked={checked}
        ></input>
      </td>
      <td id="name">{name}</td>
      <td>{email}</td>
      <td>
        {role.map((value, index) => {
          if (index < role.length - 1) {
            return value.type + "/";
          }
          return value.type;
        })}
      </td>
      <td>{orderCount}</td>
      <td>
        {!head && (
          <Link to={`${match.url}/${id}`}>
            {" "}
            <span>Edit</span>{" "}
          </Link>
        )}
      </td>
    </tr>
  );
};

export interface PaginationProps {
  total_pages: number;
  current_page: number;
  users_per_page: number;
  total_users: number;
  changePage(direction: string): any;
}

export const Pagination: React.FC<PaginationProps> = ({
  total_pages,
  current_page,
  users_per_page,
  total_users,
  changePage,
}) => {
  let top_value = 0;
  if (users_per_page * current_page > total_users) {
    top_value = total_users;
  } else {
    top_value = users_per_page * current_page;
  }
  return (
    <React.Fragment>
      {total_pages > 1 && (
        <div className="pagination-flex-wrapper">
          <div>
            <span className="pagination-overview">{`${
              users_per_page * (current_page - 1) + 1
            } - ${top_value} of ${total_users} items`}</span>
          </div>
          <div className="pagination-div">
            <span
              className={
                current_page > 1
                  ? "pagination-arrow-left active"
                  : "pagination-arrow-left"
              }
              onClick={() => {
                changePage("left");
              }}
            >
              {"<"}
            </span>
            <span>{`${current_page}   of   ${total_pages}`}</span>
            <span
              className={
                current_page < total_pages
                  ? "pagination-arrow-right active"
                  : "pagination-arrow-right"
              }
              onClick={() => {
                changePage("right");
              }}
            >
              {">"}
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
