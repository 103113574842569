import { FC, useMemo } from "react";
import { RadialBarChart, RadialBar, Cell } from "recharts";
import cn from "classnames";
import {
  CompatibilityDetails,
  CompatibilityLevel,
} from "../Compatibility.type";
import "./CompatibilityChart.scss";
import {
  COMPATIBILITY_COLORS,
  GROWTH_ALLIES_SCORE,
  SUPPORTIVE_COLLABORATORS_SCORE,
  SYNERGISTIC_PARTNERS_SCORE,
} from "../Compatibility.const";
import fingerprintSmall from "../../../assets/media/fingerprint-small.svg";

type Props = {
  users: CompatibilityDetails[];
  selectedUser: string;
  isLoading?: boolean;
};

export const CompatibilityChart: FC<Props> = ({
  users,
  selectedUser,
  isLoading,
}) => {
  const data = useMemo(() => {
    return [
      {
        id: CompatibilityLevel.ConflictProne,
        score: isLoading
          ? 100
          : (users.filter((u) => u.score < GROWTH_ALLIES_SCORE).length /
              users.length) *
            100,

        fill: COMPATIBILITY_COLORS[CompatibilityLevel.ConflictProne],
      },
      {
        id: CompatibilityLevel.GrowthAllies,
        score: isLoading
          ? 100
          : (users.filter(
              (u) => u.score >= 26 && u.score < SUPPORTIVE_COLLABORATORS_SCORE
            ).length /
              users.length) *
            100,
        fill: COMPATIBILITY_COLORS[CompatibilityLevel.GrowthAllies],
      },
      {
        id: CompatibilityLevel.SupportiveCollaborators,
        score: isLoading
          ? 100
          : (users.filter(
              (u) => u.score >= 51 && u.score < SYNERGISTIC_PARTNERS_SCORE
            ).length /
              users.length) *
            100,
        fill: COMPATIBILITY_COLORS[CompatibilityLevel.SupportiveCollaborators],
      },
      {
        id: CompatibilityLevel.SynergisticPartners,
        score: isLoading
          ? 100
          : (users.filter((u) => u.score >= SYNERGISTIC_PARTNERS_SCORE).length /
              users.length) *
            100,
        fill: COMPATIBILITY_COLORS[CompatibilityLevel.SynergisticPartners],
      },
    ];
  }, [users, isLoading]);

  return (
    <div style={{ position: "relative" }}>
      <div className="compat-chart-mask">
        <div className="compat-chart-person">
          <img src={fingerprintSmall} />

          <div>
            {selectedUser ||
              "Please, select a user from the Compatibility Matrix"}
          </div>
        </div>

        <RadialBarChart
          width={500}
          height={500}
          //   cx="50%"
          //   cy="50%"
          innerRadius="50%"
          outerRadius="100%"
          barSize={1}
          data={data}
        >
          <RadialBar
            // minAngle={15}
            // label={{ position: "insideStart", fill: "#fff" }}
            background={{ fill: "#31317c" }}
            // clockWise
            dataKey="score"
            // onClick={(e) => console.log(e)}
          />
        </RadialBarChart>
      </div>

      <RadialBarChart
        width={500}
        height={500}
        //   cx="50%"
        //   cy="50%"
        innerRadius="50%"
        outerRadius="100%"
        barSize={10}
        data={data}
      >
        <RadialBar
          // minAngle={15}
          // label={{ position: "insideStart", fill: "#fff" }}
          // background
          // clockWise
          dataKey="score"
          onClick={(e) => console.log(e)}
        >
          {data.map((entry, index) => {
            // if (entry.id === "100%") {
            //   return null;
            // }

            return (
              <Cell
                key={entry.id}
                className={cn("compat-lvl", `compat-lvl-${entry.id}`)}
                fill={entry.fill}
                cursor={"pointer"}
                // onPointerOver={(e) => e.target}
              />
            );
          })}
        </RadialBar>
      </RadialBarChart>
    </div>
  );
};
